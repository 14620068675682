<template>
  <AppMainContainer>
    <template #center>
      <v-container
        fill-height
        style="margin-top: 30%"
      >
        <AppLoader />
      </v-container>
    </template>
  </AppMainContainer>
</template>

<script>
import AppLoader from '@/components/AppLoader'
import AppMainContainer from '@/components/common/layout/AppMainContainer'

export default {
  name: 'PageUpdatePassword',
  components: { AppMainContainer, AppLoader },

  data () {
    return {
      email: null,
      isSuccess: false,
      id: ''
    }
  },

  created () {
    this.getResetPasswordDate()
  },

  methods: {
    getResetPasswordDate () {
      try {
        this.$store.dispatch('users/getShortLinkTarget', { shortCode: this.$route.params.shortCode })
          .then(({ data }) => {
            this.AppLogger.info(data, 'RESPONSE')
            if (data) {
              const { targetUrl } = data
              this.AppLogger.info(targetUrl)

              window.location.replace(targetUrl)
            } else {
              this.id = data.id
            }
          })
      } catch (error) {
        this.AppLogger.info(error, 'error')
        console.log(error.response, 'error.response')
      }
    },
  },

}
</script>

<style lang="sass">
.centered-block
  width: 200px
  height: 200px
  background-color: lightblue
  text-align: center
  line-height: 200px
</style>
